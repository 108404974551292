<template>
    <div>
        <st-modal
            :id="modalId"
            hide-header-delimiter
            no-close-on-backdrop
            no-close-on-esc
            hideable
            size="xl"
            customClass="form-modal"
            ref="modal"
            :title="$t('URBANISM_REGULATIONS_PICKER.TITLE')"
            @modalHidden="hide"
            @modalClose="hide"
        >
            <template #body>
                <loader v-if="isLoading"></loader>
                <div class="row rlu-modal-body" v-if="items.length">
                    <div class="col col-5 select-container">
                        <div class="font-weight-bold text-dark">
                            {{ $t('URBANISM_REGULATIONS_PICKER.SELECT_HEADER') }} *
                        </div>
                        <treeselect
                            name="application-urbanism-requlation"
                            searchable
                            clearable
                            multiple
                            always-open
                            :options="items"
                            v-model="value"
                            :placeholder="$t('URBANISM_REGULATIONS_PICKER.SELECT_PLACEHOLDER')"
                            value-consists-of="LEAF_PRIORITY"
                            :max-height="400"
                            disable-branch-nodes
                            @select="previewItem"
                            @deselect="clearPreviewItem"
                            :default-expand-level="1"
                        >
                            <div slot="value-label" slot-scope="{ node }">{{ node.raw.display_name }}</div>
                        </treeselect>
                        <div class="d-flex mt-auto flex-column">
                            <div class="form-group">
                                <b-form-checkbox
                                    size="lg"
                                    v-model="forceUpdate"
                                >
                                    {{ $t('URBANISM_REGULATIONS_PICKER.FORCE_UPDATE_LABEL') }}
                                </b-form-checkbox>
                            </div>
                            <div>{{ helpText }}</div>
                        </div>
                    </div>
                    <div class="col col-7 preview-container">
                        <div class="font-weight-bold text-dark">
                            {{ $t('URBANISM_REGULATIONS_PICKER.PREVIEW_HEADER') }}
                        </div>
                        <div class="preview-content">
                            <template v-if="selected.length">
                                <h6 class="p-3 font-weight-bold text-dark"> {{ selected[0].zone_key }} </h6>
                                <div class="preview-item mb-5" v-for="entry in selected" :key="entry.id">
                                    <div class="preview-item-title font-weight-bold p-3">{{ entry.category_key }}</div>
                                    <div class="preview-item-content p-3">
                                        <div v-if="entry.content" v-html="entry.content"></div>
                                        <div v-else>{{ $t('URBANISM_REGULATIONS_PICKER.ENTRY_NOT_DEFINED') }}</div>
                                    </div>
                                </div>
                            </template>
                            <div v-else class="d-flex justify-content-center align-items-center h-100">
                                <span>{{ $t('URBANISM_REGULATIONS_PICKER.PREVIEW_HELP_TEXT') }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else class="text-center">{{ $t('URBANISM_REGULATIONS_PICKER.NO_DATA_FOUND') }}</div>
            </template>
            <template #footer>
                <div class="d-flex justify-content-between w-100">
                    <st-button size="large" variant="link" :callback="hide">
                        {{ $t("GENERAL.BUTTON.CANCEL") }}
                    </st-button>
                    <st-button
                        size="large"
                        variant="primary"
                        :callback="doSubmit"
                        :disabled="!canSubmit"
                        >
                        <span>{{ $t("GENERAL.BUTTON.COMPLETE") }}</span>
                    </st-button>
                </div>
            </template>
        </st-modal>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { generateRandomId } from '@/core/helpers/globalMethods';
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

export default {
    name: 'ApplicationUrbanismRegulationPicker',
    components: {
        Treeselect,
    },
    data() {
        return {
            modalId: `st-modal-urbanism-regulations-${generateRandomId()}`,
            open: false,
            value: [],
            selected: [],
            items: [],
            loadedContent: {},
            forceUpdate: false,
        }
    },
    computed: {
        ...mapGetters({
            record: 'applications/form/record',
            uat_ids: 'applications/form/getUatIdsFromRealEstates',
            loading: 'shared/loading',
        }),
        canSubmit() {
            return (this.record?.id && this.value.length);
        },
        isLoading() {
            return this.loading['applications/manualCompletion'];
        },
        helpText() {
            return this.$t(`URBANISM_REGULATIONS_PICKER.${this.forceUpdate ? 'HELP_TEXT_FORCE_UPDATE' : 'HELP_TEXT_NOT_FORCE_UPDATE'}`);
        }
    },
    methods: {
        ...mapActions({
            getUrbanismRegulationZones: 'applications/form/findUrbanismRegulations',
            getUrbanismRegulationEntriesByZoneId: 'applications/form/findUrbanismRegulationEntriesByZone',
            doManualCompletion: 'applications/form/doManualCompletion',
        }),
        hide() {
            this.$emit('hide');
        },
        async previewItem(node) {
            const zoneId = node.id;
            if (!this.loadedContent[zoneId]) {
                this.loadedContent[zoneId] = await this.getUrbanismRegulationEntriesByZoneId(node.id);
            }
            this.selected = this.loadedContent[zoneId];
        },
        clearPreviewItem() {
            this.selected = [];
        },
        doSubmit() {
            if (this.canSubmit) {
                const payload = {
                    id: this.record.id,
                    values: {
                        zone_ids: this.value
                    },
                    forceUpdate: this.forceUpdate,
                };
                this.doManualCompletion(payload).then(() => {
                    this.hide();
                });
            } else {
                this.showErrorNotification();
            }
        },
        showErrorNotification() {
            this.$notify({
                type: 'error',
                message: this.$t('URBANISM_REGULATIONS_PICKER.COULD_NOT_SUBMIT_MESSAGE'),
            });
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.$refs['modal'].show();
        });
    },
    async created() {
        if (this.uat_ids.length) {
            this.items = await this.getUrbanismRegulationZones(this.uat_ids);
        }
    },
};
</script>
<style lang="scss" scoped>
    .rlu-modal-body {
        height: 500px;
        .select-container {
            display: flex;
            flex-direction: column;
        }
        .preview-container {
            display: flex;
            flex-direction: column;
            .preview-content {
                height: 480px;
                overflow-y: auto;
                border-radius: 5px;
                border: 1px solid #ddd;
                .preview-item {
                    .preview-item-title {
                        background-color: #f5f5f5;
                    }
                }
            }
        }
    }
</style>
